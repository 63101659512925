/* BASE SETTINGS
------------------------------ */

/* TOP PAGE BASE SETTINGS
------------------------------ */
.top-strongpoints {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	.unit-title {
		text-align: center;
		img {
			display: block;
			margin: 0 auto;
			max-width: 100%;
		}
		p.tagline {
			font-weight: bold
		}
	}
	.points {
		padding-bottom: 4rem;
		.point {
			padding: 1rem;
			text-align: center;
			.point-wrapper {
				width: 100%;
				height: 100%;
				border: 3px solid #EB8432;
				.point-no {
					img {
						margin-top: -1.5rem;
						max-width: 100%;
						margin-bottom: -12px;
					}
				}
				img.p-diagram {
					max-width: 100%;
				}
				h3 {
					padding-top: 10px;
					font-size: 1.25rem;
					font-weight: bold;
					line-height: 1.6;
					color: #EB8432;
					span {
						display: block;
					}
				}
				.p-tagline {
					font-size: .875rem;
					color: #000;
					padding-bottom: .5rem;
					span {
						display: block;
					}
				}
				a {
					width: 100%;
					height: 100%;
				&:hover {
					text-decoration: none;
					opacity: 0.7;
				}
				}
			&.rounded.bdr-t1 {
				border-radius: .75rem !important;
			}
			}
		}
	}
}

.top-colordanball {
	.bg-wrapper {
		background: #f58700;
	}
	padding-top: 70px;
	background: url(../img/common/wave_wh_or.png) top center repeat-x;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		background: #f58700;
	}
	.unit-title {
		color: #fff;
		text-align: center;
		h2 {
			font-size: 1.375rem;
			font-weight: bold;
			padding-bottom: 2rem;
			background: url(../img/common/divider_wh.png) bottom center no-repeat;
		}
		p.tagline {
			font-weight: bold;
		}
	}
	.colors {
		.color {
			text-align: center;
			color: #fff;
			img {
				max-width: 100%;
				border-radius: 1rem;
				margin-bottom: .5rem;
				box-shadow:1px 1px 3px -1px #b2b2b2;
			}
		h3 {
			font-size: 1.25rem;
			margin-bottom: .25rem;
			a {
				color: #fff;
			&:hover {
				text-decoration: none;
			}
			}
		}
		p {
			font-size: .875rem;
		}
		}
	}
}


.module-choices {
	background: url(../img/common/wave_orange.png) top center repeat-x, url(../img/common/wave_white_tr.png) bottom center repeat-x;
	.container {
		background: url(../img/common/wave_orange.png) top center no-repeat, url(../img/common/wave_white_tr.png) bottom center no-repeat, url(../img/top/choice_bkg.jpg) top center repeat-y;
	}
}


.btn-post-link {
	border-radius: .25rem;
	color: #000;
	background-color: #EBD9CA;
	// border-color: #000;
	// border-width: 2px;
&:hover {
	color: #000;
	background-color: transparent;
	border-color: #000;
}
}




.top-info {
	padding-top: 70px;
	padding-bottom: 70px;
	background: url(../img/common/color_up.png) top center repeat-x, url(../img/common/info_bottom.png) bottom center repeat-x;
	.bg-wrapper {
		background: #f58700;
		.container {
			max-width: 1200px;
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
		}
	}

	.unit-title {
		color: #fff;
		text-align: center;
		h2 {
			margin-bottom: 0;
			font-size: 1.9375rem;
			letter-spacing: 0.2rem;
			padding-bottom: 2rem;
			background: url(../img/common/divider_wh.png) bottom center no-repeat;
		}
	}
	.informations {
		.info {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			text-align: center;
			h3 {
				font-size: 1rem;
				color: #fff;
				margin-bottom: .25rem;
				a {
					color: #fff;
				}
			}
			p {
				color: #fff;
				font-size: .8135rem;
				margin-bottom: 0;
			}
		}
	}
}

.top-blog {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 70px;
		padding-bottom: 2rem;
	}
	.unit-title {
		color: #000;
		text-align: center;
		h2 {
			font-size: 1.9375rem;
			letter-spacing: 0.2rem;
			padding-bottom: 2rem;
			background: url(../img/common/divider_or.png) bottom center no-repeat;
		}
	}
	.posts {
		text-align: center;
		ul {
			color: #333;
			padding-left: 0;
			list-style: none;
			li {
				a.post-title {
					color: #333;
					font-size: .875rem;
					margin-right: .5rem;
				}
				span {
					font-size: .8175rem;
				}
			}
		}
	}
}



/* RESPONSIVE CSS
-------------------------------------------------- */
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}

// Medium devices (tablets, less than 1200px)
@media (max-width: 1199px) {

.top-strongpoints {
	.points {
		.point {
			.point-wrapper {
				img.p-diagram {
					max-width: 100%;
				}
				h3 {
					font-size: 1.25rem;
					line-height: 1.6;
					span {
						display: block;
					}
				}
				.p-tagline {
					font-size: .875rem;
					span {
						display: block;
					}
				}
			}
		}
	}
}


	.top-colordanball {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
		.colors {
			.color {
				padding-left: .5rem;
				padding-right: .5rem;
			}
		}
	}
	.top-choices {
		.container {
			max-width: 1200px;
			padding-left: 15px;
			padding-right: 15px;
		}
		.choices {
			.choice {
				padding-left: .5rem;
				padding-right: .5rem;
			}
		}
	}

	.top-info,
	.top-blog {
		.bg-wrapper {
			.container {
				max-width: 1200px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}

}


@media (min-width: 992px) and (max-width: 1199px) {
	.top-strongpoints {
		.points {
			.point {
				.point-wrapper {
					img.p-diagram {
						max-width: 90%;
					}
					h3 {
						font-size: 1.25rem;
						line-height: 1.4;
						span {
							display: block;
						}
					}
					.p-tagline {
						// text-align: left;
						font-size: .875rem;
						padding-left: .75rem;
						padding-right: .75rem;
						span {
							display: inline;
						}
					}
				}
			}
		}
	}
}



// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}



// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {

	.top-strongpoints {
		.points {
			.point {
				.point-wrapper {
					img.p-diagram {
						max-width: 80%;
					}
					h3 {
						font-size: 1.25rem;
						line-height: 1.4;
						span {
							display: block;
						}
					}
					.p-tagline {
						// text-align: left;
						font-size: .875rem;
						padding-left: 1rem;
						padding-right: 1rem;
						span {
							display: inline;
						}
					}
				}
			}
		}
	}

}

// Extra small devices (portrait phones, less than 767px)
@media (max-width: 767px) {


	.top-strongpoints {
		.unit-title {
			p.tagline {
				padding-left: 15px;
				padding-right: 15px;
				span {
					display: block;
				}
			}
		}
		.points {
			.point {
				.point-wrapper {
					img.p-diagram {
						max-width: 98%;
					}
					h3 {
						font-size: 1rem;
					}
					.p-tagline {
						text-align: left;
						font-size: .875rem;
						padding-left: 1rem;
						padding-right: 1rem;
						span {
							display: inline;
						}
					}
				}
			}
		}
	}


	.top-colordanball {
		.colors {
			.color {
				margin-bottom: .5rem;
			}
		}
	}

.top-info {
	.informations {
		.info {
			margin-bottom: 1rem;
			p {
				span {
					display: block;
				}
			}
		}
	}
}

.top-blog {
	.posts {
		ul {
			li {
				padding-left: 1.5rem;
				padding-right: 1.5rem;
				margin-bottom: 1rem;
				a.post-title {
					margin-right: 0;
				}
				span {
					display: block;
				}
			}
		}
	}
}


}